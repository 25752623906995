body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(250, 248, 245);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo-img {
  width: 60px;
  height: auto;
}

.hero {
  width: 100%;
  height: 70vh;
  background-image: url('components/HeroImage/background.webp');
  background-size: cover;
  background-position: 50%;
  /* background-attachment: fixed; */
  /* background-attachment: scroll !important; */
  background-position-y: 55%;
  border-radius: 8px;
}

.heroText {
  padding: 12px;
  padding-bottom: 24px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* align-items: flex-end; */
  height: 100%;
  gap: 8px;
  font-family: GT America Extended Medium,sans-serif;
}
.btn {
  background-color: white;
  padding: 14px 20px;
  margin-top: 16px;
  color: black;
  text-align: center;
  text-transform: uppercase;
  font-family: GT America Standard Medium,sans-serif;
  font-size: .875rem;
  letter-spacing: .06em;
  line-height: 1.25;
  border-radius: .25rem;
  font-weight: 600;
  text-decoration: none;
  border: 2px solid white;
  transition-duration: .4s;
}

.btn:hover {
  border: 2px solid white;
  transition-duration: .4s;
}

.navbar-nav .nav-link.active {
  font-weight: 500;
  color: #b1a16d;
}
.navbar-nav .nav-link:hover, .navbar-brand {
  cursor: pointer;
}

.newArrivalProduct img {
  height: 23vw;
  width: auto;
  border-radius: 8px;
}

.newArrivalProduct {
  transition-duration: .6s;
}

.newArrivalProduct:hover {
  scale: 1.05;
  transition-duration: .6s;
}


.newArrivalContainer {
  display:  flex;
  justify-content: space-around;
  gap: 30px;
  padding: 20px;
  flex-wrap: wrap;
}

.texts {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 20px;
}

.line {
  background-color: black;
  height: 1px;
  width: 300px;
}

.lines {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.heroImg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to right, #b1a16d 30%, #c0b5b3);
  padding: 0;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 5em;
}

.heroText {
  margin-left: 2em;
  min-width: 400px;
}

.heroText h1 {
  font-size: 2em;
}

.heroImg img {
  /* width: 100%; */
  width: 600px;
  height: auto;
  /* margin-right: -50px; */
}

.allProducts {
  color: black;
  font-size: 1.2em;
  transition-duration: 300ms;
}

.allProducts:hover {
  color: white;
  transition-duration: 300ms;
  cursor: pointer;
}

.productsPageWrapper {
  display: flex;
  gap: 2em;
  flex-wrap: wrap;
  justify-content: space-around;
}

#carouselExampleCaptions, #carouselExampleCaptions2 {
  width: 500px;
  height: 500px;
}

.carousel-inner, .carousel-item, .carousel-item img {
  height: 100%;
  width: 100%;
  border-radius: 8px;
}
.carousel-item img {
  object-fit: cover;
}

.sliderWrapper {
  display: flex;
  /* flex-direction: column; */
  gap: 2em;
  background: linear-gradient(to right, #b1a16d, #faf8f5 80%);
  padding: 40px;
}

.sliderWrapper2 {
  background: linear-gradient(to right,  #faf8f5, #b1a16d 80%);
}

.sliderWrapper .carousel {
  flex-grow: 1;
}

.sliderWrapper .text {
  flex-grow: 1;
  max-width: 50%;
}

.text .heading {
  font-size: 2em;
}

.text .heading2 {
  font-size: 1.5em;
}

.text .subheading {
  color: rgb(95, 95, 95);
  margin-bottom: 1em;
  font-size: 1.2em;
}

.text .paragraph {
  font-size: 1em;
  color: gray;
}

.text li, .text p {
  color: gray;
}

.newArrivalsCarousel#carouselExampleIndicators {
  height: 100vw;
  max-height: 500px;
  width: 400px;
  display: none;
}

.newArrivalsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .newArrivals {
      font-size: 1.5rem;
      flex-grow: 1;
  }
  .lines {
      gap: 20px;
  }
  .line {
      width: 30vw;
  }
  .heroImg {
    /* justify-content: center; */
    min-height: 500px;
  }
  .heroImg img {
    scale: .8;
  }
  .heroText h1 {
    font-size: 1.5em;
  }
  .sliderWrapper {
    flex-direction: column;
  }
  .sliderWrapper .text {
    max-width: none;
  }
  #carouselExampleCaptions, #carouselExampleCaptions2 {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .heroImg {
    justify-content: center;
  }
  .heroImg img {
    display: none;
  }
  .heroText {
    margin: 0;
  }
  .newArrivalsCarousel#carouselExampleIndicators {
    display: block;
  }
  .newArrivalContainer {
    display: none;
  }
}

@media screen and (max-width: 450px ) {
  .text .heading, .text .heading2 {
    font-size: 1.3em;
  }
  .text .subheading {
    font-size: 1em;
  }
  #carouselExampleCaptions, #carouselExampleCaptions2 {
    height: 200px;
  }
  .newArrivalsCarousel#carouselExampleIndicators {
    width: 100%;
  }
  .line {
    width: 50px;
  }
}

@media screen and (max-width: 350px) {
  .heroText {
    min-width: 100%;
  }
}

@media (hover: none) {
  .hero {
      background-attachment: initial;
  }
}

