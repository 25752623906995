.container {
    padding: 30px 0px;
    /* margin-top: 30px; */
    padding-top: 100px;
    background-color: #b1a16d;
}
h2 {
    font-size: 1.5rem;
    text-align: center;
    font: normal normal normal 36px/1.4em didot-w01-italic,didot-w05-italic,serif;
}

.products {
    /* background-color: #f6f4f1; */
    /* background-color: rgb(36, 42, 53); */
    background-color: #b1a16d;
    padding: 12px;
    border-radius: 8px;
    margin-top: 30px;
    margin-bottom: 50px;

}

.texts {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 20px;
}

.line {
    background-color: black;
    height: 1px;
    width: 300px;
}

.lines {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

.productsWrapper {
   display: flex;
   gap: 15px;
   border-radius: 8px;
   overflow-x: scroll; 
   padding-top: 20px;
}

.productsWrapper::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .productsWrapper {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

.product {
    background-color: white;
    width: fit-content;
    padding: 12px;
    border-radius: 8px;
    transition-duration: .6s;
}

.product:hover {
    transform: translateY(-20px);
    transition-duration: .6s;
    cursor: pointer;
}

.productImg {
    width: 250px;
    height: 250px;
    border-radius: .25em;
}
.wrap {
    width: 250px;
    height: 216px;
    overflow: hidden;
    margin-bottom: 10px;
    object-position: center;
}

.label {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 6px;
}

.subLabel {
    font-size: .75rem;
    color: gray;
}


@media screen and (max-width: 500px) {
    .container {
        padding: 10px;
        padding-top: 30px;
    }
    .products {
        padding: 0;
    }
    .newArrivals {
        font-size: 1.5rem;
        flex-grow: 1;
    }
    .lines {
        gap: 20px;
    }
    .line {
        width: 50px;
    }
    .productImg {
        width: 200px;
        height: 200px;
    }
    .wrap {
        width: 200px;
        height: 172px;
    }
}