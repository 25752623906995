
.wallpaper {
    width: 100%;
    height: 400px;
    background-image: url('https://img.freepik.com/premium-photo/walnuts-hd-8k-wallpaper-stock-photographic-image_871881-22105.jpg');
    background-attachment: fixed;
    background-position: right;
    background-size: cover;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    font-family: 'Times New Roman', Times, serif;
  }

@media screen and (max-width: 500px) {
    .wallpaper {
        font-size: 24px;
    }
}

@media (hover: none) {
    .wallpaper {
        background-attachment: initial;
    }
 }