.product {
    background-color: rgb(250, 248, 245);
    width: fit-content;
    padding: 12px;
    border-radius: 8px;
    transition-duration: .6s;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, .3);
}

.product:hover {
    transform: translateY(-20px);
    transition-duration: .6s;
    cursor: pointer;
}

.product:hover .productImg {
    /* background-image: url('./ProductImages/almond.jpg'); */
    transition-duration: 1s;
}

.productImg {
    width: 250px;
    height: 250px;
    border-radius: .25em;
    /* background-image: url('./ProductImages/almond\ 2.jpg'); */
    background-size: cover;
    background-position: center;
    /* transition-duration: 1s; */
}
.wrap {
    /* width: 250px; */
    /* height: 216px; */
    /* overflow: hidden; */
    margin-bottom: 10px;
    object-position: center;
}

.label {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 6px;
}

.subLabel {
    font-size: .75rem;
    color: gray;
}

@media screen and (max-width: 767px) {
    .productImg {
        width: 200px;
        height: 200px;
    }
}
@media screen and (max-width: 503px) {
    .productImg {
        width: 160px;
        height: 160px;
    }
}
@media screen and (max-width: 423px) {
    .productImg {
        width: 270px;
        height: 270px;
    }
}