.container {
    /* background-color: black; */
    /* padding: 12px; */
}

.hero {
    width: 100%;
    height: 100vh;
    background-image: url('./background.webp');
    background-size: cover;
    background-position: 55%;
    background-attachment: fixed;
    /* background-attachment: scroll !important; */
    background-position-y: top;
    /* border-radius: 8px; */
}

.heroText {
    padding: 12px;
    padding-bottom: 24px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* align-items: flex-end; */
    height: 100%;
    gap: 8px;
    font-family: GT America Extended Medium,sans-serif;
}
.btn {
    background-color: white;
    padding: 14px 20px;
    margin-top: 16px;
    color: black;
    text-align: center;
    text-transform: uppercase;
    font-family: GT America Standard Medium,sans-serif;
    font-size: .875rem;
    letter-spacing: .06em;
    line-height: 1.25;
    border-radius: .25rem;
    font-weight: 600;
    text-decoration: none;
}

@media (hover: none) {
    .hero {
        background-attachment: initial;
    }
 }