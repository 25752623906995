.header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    /* background-color: rgba(0, 0, 0, .5); */
    background-color: rgb(225, 227, 231, .8);
    border: 3px solid black;
    font-size: 1.5rem;
    line-height: 1.25;
    /* padding: 12px; */
    color: black;
    display: flex;
    justify-content: center;
    height: 60px;
    /* align-items: center; */
}
.navbarBrand {
    border-left: 3px solid black;
    border-right: 3px solid black;
    color: black;
    text-decoration: none;
    padding: 20px;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 500px) {
    .header {
        font-size: 1rem;
        height: 50px;
        border: 0;
        justify-content: left;
    }
    .navbarBrand {
        border: 0;
    }
}