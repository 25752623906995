body, .root, html {
  padding: 0;
  width: 100vw;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
