.Quote {
    height: 400px;
    background-color: #b1a16d;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 2rem;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 100;
    font-style: normal;
    text-align: center;
}

@media screen and (max-width: 500px) {
    .Quote {
        font-size: 1.3rem;
    }
}